(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/hooks/assets/javascripts/use-get-is-draw-cancelled.js') >= 0) return;  svs.modules.push('/components/pool-betting/hooks/assets/javascripts/use-get-is-draw-cancelled.js');
"use strict";

const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  drawStateEnums
} = svs.components.sport.tipsenShared;
const useGetIsDrawCancelled = _ref => {
  var _drawResultResponse$d;
  let {
    draw
  } = _ref;
  const drawResultResponse = tipsenApi.useGetDrawResultQuery({
    drawNumber: draw === null || draw === void 0 ? void 0 : draw.drawNumber,
    productId: draw === null || draw === void 0 ? void 0 : draw.productId
  }, {
    skip: (draw === null || draw === void 0 ? void 0 : draw.drawState.toLowerCase()) !== drawStateEnums.FINALIZED
  });
  return drawResultResponse === null || drawResultResponse === void 0 || (_drawResultResponse$d = drawResultResponse.data) === null || _drawResultResponse$d === void 0 ? void 0 : _drawResultResponse$d.cancelled;
};
setGlobal('svs.components.poolBetting.hooks.useGetIsDrawCancelled', useGetIsDrawCancelled);

 })(window);